<template>
  <div :style="{ height: screeHeight + 'px' }" style="background-color: #F7F7F7">
    <div class="hearder">
      池州市社会保障卡居民服务“一卡通”
    </div>
    <van-nav-bar title="选择景区" />
    <van-search
      v-model="searchValue"
      shape="round"
      background="#FF4920"
      placeholder="搜索商品 景点"
      show-action
      @focus="toSearch"
    >
      <template #action>
        <div
          @click="toPerson"
          style="font-size: 25px; line-height: 20px"
        >
        <img src="../../assets/person.png" style="width: 29px;">
        </div>
      </template>
    </van-search>
    <myList :list="list" :height="60" />
    <div class="footer">
      粤公网安备 44011302001352号
      粤ICP备16027461号
      广东德生科技股份有限公司 版权所有
    </div>
  </div>
</template>
<script>
import myList from "../../components/indexList";
// import { list } from "../../data.js";
export default {
  name: "index",
  components: {
    myList,
  },
  data() {
    return {
      searchValue: "",
      screenWidth: document.documentElement.clientWidth, // 屏幕宽
      screeHeight: document.documentElement.clientHeight, // 屏幕高
      list: [],
    };
  },
  created() {
    // this.$router.push({path:'/href'})
    this.queryList();
  },
  mounted() {
    // this.$router.push({path:'/href'})
  },
  methods: {
    queryList() {
      this.$http({
        method: "POST",
        url: `${this.apis.groupSubOrgList}?orgId=${this.mydata.orgId}&isSales=0`,
        withCredentials: true,
      }).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          this.$store.commit("saveIndexList", res.data); //存给搜索页面
        }
      });
    },
    toPerson() {
      console.log("personal");
      try {
        this.$router.push("/personal");
      } catch (e) {
        console.log(e);
      }
    },
    toInfo(item) {
      this.$router.push({ path: "/info", query: item });
    },
    toSearch() {
      this.$router.push("/search");
    },
    onSearch() {},
  },
};
</script>
<style lang="less" scoped>
.hearder{
  position: relative;
  top: 0;
  padding: 12px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  background-color: #fff;
}
.footer{
  text-align: center;
  position: relative;
  padding: 12px;
  box-sizing: border-box;
  bottom: 0;
  width: 100%;
  font-size: 12px;
}
</style>
